import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '/assets/img/sidebar-1.jpg',
    drawer: null,
    isLogin: false,
    jwt_token: null,
    darkMode: false,
    snackbar: {
      content: '',
      color: '',
    },
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_DARKMODE (state, payload) {
      state.darkMode = payload
    },
    resetState (state) {
      state.isLogin = false
      state.jwt_token = null
    },
    snackbarShowMessage (state, payload) {
      state.snackbar = payload
    },
  },
  actions: {
    clearState ({ commit }) {
      commit('resetState')
    },
  },
  plugins: [vuexLocal.plugin],
})
