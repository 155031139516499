import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Авторизация',
      component: () => import('@/views/dashboard/component/Login'),
      meta: {
        guest: true,
        layout: 'empty',
      },
    },
    {
      name: 'Пользователи',
      path: '/users',
      component: () => import('@/views/dashboard/pages/Users'),
      meta: {
        auth: true,
        layout: 'main',
      },
    },
    {
      name: 'Проекты',
      path: '/projects',
      component: () => import('@/views/dashboard/pages/Projects'),
      meta: {
        auth: true,
        layout: 'main',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.state.isLogin) {
      next()
    } else {
      next({
        path: '/',
      })
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.state.isLogin) {
      next({
        path: '/users',
      })
    } else {
      next()
    }
  } else {
    next({})
  }
})

export default router
