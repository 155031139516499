<template>
  <base-material-snackbar
    v-model="show"
    :type="color"
    v-bind="{
      ['top']: true,
      ['right']: true
    }"
  >
    <span
      class="text-h4"
    >{{ message }}
    </span>
  </base-material-snackbar>
</template>

<script>
  export default {
    data () {
      return {
        show: false,
        message: '',
        color: 'warning',
      }
    },

    created () {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'snackbarShowMessage') {
          this.message = state.snackbar.content
          this.color = state.snackbar.color
          this.show = true
        }
      })
    },
  }
</script>
