import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/json-excel'
import snackbarPlugin from './plugins/snackbar'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'

const base = axios.create({
  baseURL: 'https://adminbot.kedprof.ru/api',
  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
})

Vue.prototype.$http = base
Vue.config.productionTip = false

Vue.use(snackbarPlugin, { store })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')

if (store.state.jwt_token != null) {
  Vue.prototype.$http.defaults.headers.common.Authorization = 'Bearer ' + store.state.jwt_token
}

Vue.prototype.$http.interceptors.response.use(function (response) {
  // Do something with response datax
  return response
}, function (error) {
  // Do something with response error
  if (error.message === 'Request failed with status code 401') {
    store.dispatch('clearState')
    router.push({ name: 'Авторизация' })
  }

  return Promise.reject(error)
})
