<template>
  <v-app>
    <snackbar />

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'MainLayout',

    components: {
      DashboardCoreAppBar: () => import('./../views/dashboard/components/core/AppBar'),
      DashboardCoreDrawer: () => import('./../views/dashboard/components/core/Drawer'),
      // DashboardCoreSettings: () => import('./components/core/Settings'),
      DashboardCoreView: () => import('./../views/dashboard/components/core/View'),
      Snackbar: () => import('./../components/base/Snackbar'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
