<template>
  <v-app>
    <dashboard-core-view />
  </v-app>
</template>
<script>
  export default {
    name: 'EmptyLayout',

    components: {
      DashboardCoreView: () => import('./../views/dashboard/components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
